import { Badge, Typography,Button, TextField, TextareaAutosize, ButtonGroup } from '@mui/material';
import React from 'react';
import API from '../API';

export default class RepliesScreen extends React.Component {

    constructor() {
        super();


       
        this.state = {replies: [...global.cache.replies], viewingType: 0};
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
       
    }

    
    render() {
        return <div style={{display:"flex", flexDirection:"column"}}>

            <div style={{display:"flex",justifyContent:"space-between",flex:1}}>
            <Typography style={{marginBottom: 20}} variant='h5'>Hızlı Yanıtlar ({this.state.replies.filter(s => s.type == this.state.viewingType).length})</Typography>
            <ButtonGroup aria-label="Basic button group">
                <Button onClick={() => this.setState({viewingType: 0})} variant={this.state.viewingType == 0?'contained':'outlined'}>Canlı Destek</Button>
                <Button onClick={() => this.setState({viewingType: 1})} variant={this.state.viewingType == 1?'contained':'outlined'}>Bildirimler</Button>
                
            </ButtonGroup>
            </div>
            

         
            {this.state.viewingType == 0 && this.state.replies.filter(s => s.type == 0).map((x, i) => <div style={{display:"flex",gap:5, borderTopWidth:1, borderTopStyle:"solid", borderTopColor:"gray",paddingBottom:10, paddingTop:10}} key={i}>


                {!x.edit && <>
                <Typography style={{flex:.3}} variant='h6'>{x.code}</Typography>
                <Typography  style={{flex:.7}} >{x.msg}</Typography>
                <Button onClick={() => {x.edit = true; this.forceUpdate()}}>D</Button>
                </>}

                {x.edit && <>

                <TextField value={x.code} style={{flex:.3}} onChange={(e) => {x.code = e.target.value; this.forceUpdate()}} />
                <TextareaAutosize value={x.msg} style={{flex:.7}} onChange={(e) => {x.msg = e.target.value; this.forceUpdate()}} />
                
                <Button onClick={() => {

                    global.showLoadingPanel();
                    API.fetch("savereply", {reply: x}).then(e => {
                        x._id = e._id;
                        x.edit = false;
                        global.hideLoadingPanel();
                    })

                }}>S</Button>
                </>}
                
                
                
                </div>)}

                {this.state.viewingType == 1 && this.state.replies.filter(s => s.type == 1).map((x, i) => <div style={{display:"flex",gap:5, borderTopWidth:1, borderTopStyle:"solid", borderTopColor:"gray",paddingBottom:10, paddingTop:10}} key={i}>


                {!x.edit && <>
                <Typography style={{flex:.3}} variant='h6'>{x.code}</Typography>
                <div style={{display:"flex",flexDirection:"column", flex:1,gap: 10}}>
                    <div style={{display:"flex"}}>
                        <Typography>Başlık (TR):</Typography>
                        <Typography  style={{flex:.7}} >{x.titleTR}</Typography>
                    </div>

                    <div style={{display:"flex"}}>
                        <Typography>Metin (TR):</Typography>
                        <Typography  style={{flex:.7}} >{x.bodyTR}</Typography>
                    </div>

                    <div style={{display:"flex"}}>
                        <Typography>Başlık (EN):</Typography>
                        <Typography  style={{flex:.7}} >{x.titleEN}</Typography>
                    </div>

                    <div style={{display:"flex"}}>
                        <Typography>Metin (EN):</Typography>
                        <Typography  style={{flex:.7}} >{x.bodyEN}</Typography>
                    </div>
                </div>
                
                
                <Button onClick={() => {x.edit = true; this.forceUpdate()}}>D</Button>
                </>}

                {x.edit && <>

                <TextField value={x.code} style={{flex:.3}} onChange={(e) => {x.code = e.target.value; this.forceUpdate()}} />
                
                <div style={{display:"flex",flex:1, flexDirection:"column",gap: 10}}>
                <div style={{display:"flex",flex:1}}>
                    <Typography>Başlık (TR):</Typography>
                    <TextareaAutosize value={x.titleTR} style={{flex:.7}} onChange={(e) => {x.titleTR = e.target.value; this.forceUpdate()}} />
                </div>
                
                <div style={{display:"flex",flex:1}}>
                    <Typography>Metin (TR):</Typography>
                    <TextareaAutosize value={x.bodyTR} style={{flex:.7}} onChange={(e) => {x.bodyTR = e.target.value; this.forceUpdate()}} />
                </div>

                <div style={{display:"flex",flex:1}}>
                    <Typography>Başlık (EN):</Typography>
                    <TextareaAutosize value={x.titleEN} style={{flex:.7}} onChange={(e) => {x.titleEN = e.target.value; this.forceUpdate()}} />
                </div>

                <div style={{display:"flex",flex:1}}>
                    <Typography>Metin (EN):</Typography>
                    <TextareaAutosize value={x.bodyEN} style={{flex:.7}} onChange={(e) => {x.bodyEN = e.target.value; this.forceUpdate()}} />
                </div>
                </div>

                <Button onClick={() => {

                    global.showLoadingPanel();
                    API.fetch("savereply", {reply: x}).then(e => {
                        x._id = e._id;
                        x.edit = false;
                        global.hideLoadingPanel();
                    })

                }}>S</Button>
                </>}



                </div>)}

                <Button onClick={() => {this.state.replies.push({code: "",type: this.state.viewingType, edit: true, msg: "",titleTR: "", titleEN:"",bodyTR:"",bodyEN: ""}); this.forceUpdate()}} style={{marginTop:10}}>Ekle</Button>
        </div>
    }
}

